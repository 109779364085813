<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <PrimePreloader/>
    </div>
    <div v-else>
      <div class="my-4">
        <h4 class="text-center">Список заявок на вакансии</h4>
      </div>

      <div class="my-3">
        <div v-if="+memberStatus.is_active===1">
          <div>
            <div class="fw-bold mb-1">Вы участвуете в ККК</div>
            <Button icon="pi pi-sign-out" label="Покинуть"
                    class="p-button-sm p-button-rounded p-button-text p-button-danger"
                    :loading="loadingMemberStatus" @click="saveMemberStatus(0)"/>
          </div>


          <div class="my-4">
            <div class="my-3">
              <Button icon="pi pi-refresh" label="Обновить список участников"
                      class="p-button-sm p-button-rounded p-button-text"
                      @click="getCommissionMembers"/>
            </div>
            <div v-if="commissionMembers.length">
              <div class="fw-bold mb-2">Список участников ККК:</div>
              <div v-for="(commissionMember, commissionMemberIndex) in commissionMembers" :key="commissionMemberIndex"
                   class="my-1">
                {{commissionMemberIndex+1}}. {{commissionMember.lastname}} {{commissionMember.firstname}}
              </div>
            </div>
            <div v-else>
              Список участников ККК пуст
            </div>
          </div>

        </div>
        <div v-else>
          <div class="fw-bold mb-1">Вы не участвуете в ККК</div>
          <Button icon="pi pi-sign-in" label="Принять участние" class="p-button-sm p-button-rounded p-button-text"
                  :loading="loadingMemberStatus" @click="saveMemberStatus(1)"/>
        </div>

      </div>


      <div v-if="+memberStatus.is_active===1" class="my-3">
        <DataTable :value="commissionScore_form.candidateList" showGridlines stripedRows
                   :globalFilterFields="['lastname','firstname']"
                   v-model:filters="filters"
                   :paginator="true"
                   :rows="20"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[5,10,20,30,40,50]"
                   responsiveLayout="scroll"
                   currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск кандидата"/>
              </span>
            </div>
          </template>
          <template #empty>
            Кандидат не найден
          </template>
          <template #loading>
            Идет поиск кандидатов. Пожалуйста подождите.
          </template>

          <Column header="№" :style="{width: '80px'}">
            <template #body="{index}">
              {{index+1}}
            </template>
          </Column>

          <Column header="ФИО">
            <template #body="{data}">
              <a @click.prevent="goToUser(data.user)" class="text-decoration-none" href="#">
                {{data.lastname}} {{data.firstname}}
              </a>
              <div>
                Оценка за профиль кандидата ({{data.teacher_profile_score_votes}}): <strong>{{data.teacher_profile_score_sum}}</strong>
                <br>
              </div>
              <div>
                <Button icon="pi pi-user" class="p-button-rounded p-button-secondary m-1"
                        v-tooltip.top="'Оценка за профиль'"
                        @click="openTeacherProfileScoreDialog(data.user, data.profile_types)"/>
                <Button v-if="data.profile_anon_perm == true" icon="pi pi-user" v-tooltip.top="'Анонимное голосование'"
                        class="p-button-rounded p-button-help m-1"
                        @click="openAnonymousVoteDialog(data.user)"/>
              </div>
            </template>
          </Column>

<!--          <Column v-if="decryptCheckRole('admin')||decryptCheckRole('commissionHead')" header="Контракт" class="text-center"-->
<!--                  :style="{width: '15%'}">          -->
            <Column header="Контракт" class="text-center"
                  :style="{width: '15%'}">
            <template #body="{data}">
              <div v-if="data.contracts.length">
                <Button label="Контракты" class="p-button-rounded"
                        @click="openAddContractDialog(data.user)"/>
              </div>
              <div v-else>Контрактов нет</div>
            </template>
          </Column>

          <Column header="Дисциплины">
            <template #body="{data}">
              <div v-for="(application, index) in data.applications"
                   :key="index">

                <div class="fw-bold">
                  <a @click.prevent="openDisciplineInfoDialog(data.user, application.discipline_id)"
                     class="text-decoration-none" href="#">
                    {{application.discipline_name}}
                  </a>
                </div>
                <div>
                  {{application.study_level_name}} - {{application.language}}

                  <div class="mt-2">
                    Оценка за дисциплину: <strong>{{application.discipline_teaching_score_sum}}</strong>
                    <br>
                    Количество за: <strong>{{application.kkk_anonymous_vote_sum_agree}}</strong>
                    <br>
                    Количество против: <strong>{{application.kkk_anonymous_vote_sum_disagree}}</strong>
                  </div>

                  <div class="mt-2">
<!--                    v-if="application.discipline_teaching_score_perm == true"-->
                    <Button v-if="(data.anonymous_vote_sum_agree > 0 || data.anonymous_vote_sum_disagree > 0)" icon="pi pi-heart" class="p-button-rounded p-button-success m-1"
                            v-tooltip.top="'Оценка за дисциплину'"
                            style="width: 30px; height: 30px"
                            @click="openDisciplineTeachingScoreDialog(application.kkk_interview_time_id, data.user)"/>
                    <Button icon="pi pi-file-pdf" class="p-button-rounded m-1" style="width: 30px; height: 30px"
                            v-tooltip.top="'Силлабус'"
                            @click="goToSyllabus(data.user, application.discipline_id)"/>

                    <Button v-if="application.anon_vote_perm == true" icon="pi pi-comment"
                            v-tooltip.top="'Анонимное голосование'"
                            class="p-button-rounded p-button-help m-1" style="width: 30px; height: 30px"
                            @click="openDisciplineAnonymousVoteDialog(application.kkk_interview_time_id, data.user)"/>
                  </div>
                  <!--                  <i class="pi pi-heart-fill m-1" @click="openDisciplineTeachingScoreDialog(application.kkk_interview_time_id, data.user)" style="color: green"></i>-->
                  <!--                  <i class="pi pi-file-pdf m-1" @click="goToSyllabus(data.user, application.discipline_id)" style="color: red"></i>-->
                  <!--                  <i class="pi pi-comment m-1" @click="openActionApplication(data.id, statuses.reject)" style="color: purple"></i>-->
                </div>
                <br>

              </div>
            </template>
          </Column>


          <!--          <Column header="Действия" class="text-center" :style="{width: '15%'}">-->
          <!--            <template #body="{data}">-->
          <!--              <Button class="p-button-rounded p-button-success me-1" icon="pi pi-check" @click="confirm(data.user)"/>-->
          <!--              <Button class="p-button-rounded p-button-danger" icon="pi pi-times" @click="openRejectDialog(data.user)"/>-->
          <!--            </template>-->
          <!--          </Column>-->
        </DataTable>


      </div>


    </div>
    <!--    <ConfirmDialog></ConfirmDialog>-->

    <Dialog header="Оценка профиля" v-model:visible="teacherProfileScoreDisplay" :breakpoints="{'960px': '75vw'}"
            :style="{width: '50vw'}" :closable="false">

      <div class="col-md-12">

        <div v-if="teacherProfileScoreInfo.length > 0">

          <div class="row mt-4" v-for="(item, index) in filteredTeacherProfileScoreCriterion"
               :key="index">
            <label :for="'k_'+(index+1)" class="col-md-6 col-form-label">
              {{ item.name}}</label>
            <div class="col-md-6">
              <input :id="'k_'+(index+1)" type="number" class="form-control"
                     :value="teacherProfileScoreInfo[0]['k_'+(index+1)]"
                     disabled>
            </div>
          </div>

          <div class="row mt-4">
            <label for="score_comment" class="col-md-6 col-form-label">Оставить комментарий</label>
            <div class="col-md-6">
              <textarea id="score_comment" class="form-control"
                        :value="teacherProfileScoreInfo[0]['score_comment']" disabled>
            </textarea>
            </div>
          </div>


        </div>
        <div v-else>

          <div class="row mt-4" v-for="(item, index) in filteredTeacherProfileScoreCriterion"
               :key="index">
            <label :for="'k_'+(index+1)" class="col-md-6 col-form-label">
              {{ item.name}}</label>
            <div class="col-md-6">
              <!--            :value="candidateCriterionScoreInfos[0]['k'+(index+1)]"-->
              <input :id="'k_'+(index+1)" type="number" class="form-control"
                     placeholder="От 0 до 5"
                     @change="changeTeacherProfileScore('k_'+(index+1), $event)">
            </div>
          </div>

          <div class="row mt-4">
            <label for="score_comment1" class="col-md-6 col-form-label">Оставить комментарий</label>
            <div class="col-md-6">
              <!--            :value="candidateCriterionScoreInfos[0]['score_comment']"-->
              <textarea id="score_comment1" class="form-control"
                        placeholder="Введите комментарий"
                        @change="changeTeacherProfileScore('score_comment', $event)">
            </textarea>
            </div>
          </div>

        </div>


      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeTeacherProfileScoreDialog" class="p-button-text"/>
        <Button label="Сохранить" icon="pi pi-check" @click="submitTeacherProfileScore"
                :disabled="teacherProfileScoreInfo.length > 0"/>
      </template>
    </Dialog>

    <Dialog header="Оценка" v-model:visible="disciplineTeachingScoreDisplay" :breakpoints="{'960px': '75vw'}"
            :style="{width: '50vw'}" :closable="false">

      <div class="col-md-12">

        <div v-if="disciplineTeachingScoreInfo.length > 0">

          <div class="row mt-4" v-for="(item, index) in commissionScore_form.disciplineTeachingScoreCriterion"
               :key="index">
            <label :for="'k_'+(index+1)" class="col-md-6 col-form-label">
              {{ item.name}}</label>
            <div class="col-md-6">
              <input :id="'k_'+(index+1)" type="number" class="form-control"
                     :value="disciplineTeachingScoreInfo[0]['k_'+(index+1)]"
                     disabled>
            </div>
          </div>

        </div>
        <div v-else>
          <div class="row mt-4" v-for="(item, index) in commissionScore_form.disciplineTeachingScoreCriterion"
               :key="index">
            <label :for="'k_'+(index+1)" class="col-md-6 col-form-label">
              {{ item.name}}</label>
            <div class="col-md-6">
              <input :id="'k_'+(index+1)" type="number" class="form-control"
                     placeholder="От 0 до 5"
                     @change="changeDisciplineTeachingScore('k_'+(index+1), $event)">
            </div>
          </div>
        </div>


      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeDisciplineTeachingScoreDialog" class="p-button-text"/>
        <Button label="Сохранить" icon="pi pi-check" @click="submitDisciplineTeachingScore"
                :disabled="disciplineTeachingScoreInfo.length > 0"/>
      </template>
    </Dialog>


    <Dialog header="Анонимное голосование профиля" v-model:visible="anonymousVoteDisplay"
            :breakpoints="{'960px': '75vw'}" :style="{width: '50vw'}">

      <div class="col-md-12">
        <div v-if="anonymousVoteInfo.length > 0">
          Уже проголосовали
        </div>
        <div v-else>
          <p>Согласны ли вы принять этого кандидата?</p>
        </div>


      </div>

      <template #footer>
        <Button label="Нет" icon="pi pi-times" @click="submitAnonymousVote(0)" class="p-button-danger"
                :disabled="anonymousVoteInfo.length > 0"/>
        <Button label="Да" icon="pi pi-check" @click="submitAnonymousVote(1)" class="p-button-success"
                :disabled="anonymousVoteInfo.length > 0"/>
      </template>
    </Dialog>


    <Dialog header="Анонимное голосование дисциплины" v-model:visible="disciplineAnonymousVoteDisplay"
            :breakpoints="{'960px': '75vw'}" :style="{width: '50vw'}">

      <div class="col-md-12">
        <div v-if="disciplineAnonymousVoteInfo.length > 0">
          <span v-if="disciplineAnonymousVoteInfo[0].vote == 1" style="color: green">
              Принято
          </span>
          <span v-if="disciplineAnonymousVoteInfo[0].vote == 0" style="color: red">
              Отказано
          </span>
          <div></div>
        </div>
        <div v-else>
          <p>Согласны ли вы принять этого кандидата на дисциплину
            <strong>
              {{this.commissionScore_form.candidateList.find(i=>i.user === this.discipline_anonymous_vote_user_id)?.applications.find(i=>i.kkk_interview_time_id === this.discipline_anonymous_vote_kkk_interview_time_id).discipline_name}}
            </strong>?
          </p>
        </div>


      </div>

      <template #footer>
        <Button label="Нет" icon="pi pi-times" @click="submitDisciplineAnonymousVote(0)" class="p-button-danger"
                :disabled="disciplineAnonymousVoteInfo.length > 0"/>
        <Button label="Да" icon="pi pi-check" @click="submitDisciplineAnonymousVote(1)" class="p-button-success"
                :disabled="disciplineAnonymousVoteInfo.length > 0"/>
      </template>
    </Dialog>

    <Dialog header="Информация о дисциплине" :visible="disciplineInfoDisplay" :breakpoints="{'960px': '75vw'}"
            :style="{width: '50vw'}" :closable="false">

      <div class="col-md-12">

        <div v-for="(item, index) in disciplineInfo" :key="index">
          <p>
            <b>Название дисциплины:</b>{{item.code}} {{item.discipline_name}}
          </p>
          <p>
            <b>Язык:</b> {{item.language}}
          </p>
          <p>
            <b>Уровень обучения:</b> {{item.study_level_name}}
          </p>
          <p>
            <b>Описание:</b> {{item.description}}
          </p>
          <p>
            <b>Умения:</b> {{item.abilities}}
          </p>
          <p>
            <b>Навыки:</b> {{item.skills}}
          </p>
          <p>
            <b>Знания:</b> {{item.knowledge}}
          </p>
        </div>
      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeDisciplineInfoDialog" class="p-button-text"/>
      </template>
    </Dialog>


    <!-- add contract dialog -->
    <Dialog header="Контракты" :visible="displayAddContract" :closable="false" :modal="true"
            :style="{width: '100%', maxWidth: '600px'}">
      <div class="mt-3">
        <h5 class="mb-3">{{contractUserName}}</h5>

        <div v-for="(contract, contractIndex) in contracts" :key="contractIndex" class="border rounded-3 p-2 pb-3 mb-4">
          <div class="row">
            <div class="col-md-3">Кафедра</div>
            <div class="col-md-9 fw-bold">{{contract.department_name}}</div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Выберите тип</label>
            <div class="col-md-9">
              <select class="form-control form-select" v-model="contract.contract_type">
                <option v-for="(contractType, contractTypeIndex) in contractTypes"
                        :key="contractTypeIndex"
                        :value="contractType?.id">
                  {{ contractType.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Выберите срок</label>
            <div class="col-md-9">
              <select class="form-control form-select" @input="changeContractTerm($event, contract.id)">
                <option v-for="(contractTerm, contractTermIndex) in contractTerms"
                        :key="contractTermIndex"
                        :value="contractTerm.term"
                        :selected="contractTerm.term==contract.contract_term">
                  {{ contractTerm.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="work_start_date" class="col-md-3 col-form-label">
              Дата трудового договора
            </label>
            <div class="col-md-9">
              <input id="work_start_date" type="date" class="form-control" v-model="contract.work_start_date">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="contract_start_date" class="col-md-3 col-form-label">
              С даты
            </label>
            <div class="col-md-9">
              <input id="contract_start_date" type="date" class="form-control" v-model="contract.contract_start_date">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="contract_end_date" class="col-md-3 col-form-label">
              До даты
            </label>
            <div class="col-md-9">
              <input id="contract_end_date" type="date" class="form-control" v-model="contract.contract_end_date">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Должность</label>
            <div class="col-md-9">
              <select class="form-control form-select" v-model="contract.teaching_position_id">
                <option v-for="(position, positionIndex) in teachingPositions" :key="positionIndex"
                        :value="position.id">
                  {{ position.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="mt-4 text-end">
            <Button label="Сохранить" icon="pi pi-save" @click="addContract(contract.id)"
                    v-if="decryptCheckRole('admin')||decryptCheckRole('commissionHead')" autofocus/>
          </div>
        </div>

      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeAddContractDialog" class="p-button-text"/>
      </template>
    </Dialog>
    <!-- end add contract dialog -->


  </div>
</template>

<script>
  import {mapActions, mapState, mapMutations} from 'vuex'
  import {FilterMatchMode, FilterOperator} from 'primevue/api';
  import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"
  import {convertDateInputToTimestamp} from "@/utils/helpers/date.helpers"
  import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";

  export default {
    name: 'CommissionScore',
    data() {
      return {
        loading: true,
        interval: null,
        filters: {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},

          'lastname': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'firstname': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
        },
        // checkedApplications: [],
        // rejectDisplay: false,
        // reject_user: null,

        disciplineInfoDisplay: false,
        info_discipline_id: null,
        info_user_id: null,

        teacherProfileScoreDisplay: false,
        teacherProfileScore: {
          "k_1": 0,
          "k_2": 0,
          "k_3": 0,
          "k_4": 0,
          "k_5": 0,
        },
        teacher_profile_score_user_id: null,

        disciplineTeachingScoreDisplay: false,
        disciplineTeachingScore: {},
        discipline_teaching_score_kkk_interview_time_id: null,
        discipline_teaching_score_user_id: null,

        anonymousVoteDisplay: false,
        anonymous_vote_user_id: null,
        selected_profile_types: {},

        disciplineAnonymousVoteDisplay: false,
        discipline_anonymous_vote_kkk_interview_time_id: null,
        discipline_anonymous_vote_user_id: null,
        memberStatus: {},
        commissionMembers: [],
        loadingMemberStatus: false,


        displayAddContract: false,
        contractTypes: [
          {
            id: 1,
            name: 'Штатный'
          },
          {
            id: 2,
            name: 'Совместитель'
          },
          {
            id: 3,
            name: 'Докторантура'
          },
          {
            id: 4,
            name: 'Гпх'
          },
          {
            id: 5,
            name: 'Внутренний совместитель'
          },
        ],
        contractTerms: [
          {
            term: 1,
            name: '1 год'
          },
          {
            term: 2,
            name: '2 года'
          },
          {
            term: 3,
            name: '3 года'
          },
          {
            term: 4,
            name: '1 семестр'
          }
        ],
        contracts: {},
        contractUserName: '',
      }
    },
    computed: {
      ...mapState('commissionScore', ['commissionScore_form']),
      ...mapState('kkk', ['teachingPositions']),
      disciplineInfo() {
        return this.commissionScore_form.candidateList.find(i => i.user == this.info_user_id).applications.filter(i => i.discipline_id == this.info_discipline_id)
      },
      teacherProfileScoreInfo() {
        return this.commissionScore_form.candidateList.find(i => i.user === this.teacher_profile_score_user_id)?.teacher_profile_score
      },
      disciplineTeachingScoreInfo() {
        return this.commissionScore_form.candidateList
          .find(i => i.user === this.discipline_teaching_score_user_id)?.applications
          .find(i => i.kkk_interview_time_id === this.discipline_teaching_score_kkk_interview_time_id).discipline_teaching_score
      },
      filteredTeacherProfileScoreCriterion() {
        if (this.selected_profile_types.simple == 1) {
          return this.commissionScore_form.teacherProfileScoreCriterion.filter(i => i.id != 6)
        } else if (this.selected_profile_types.lang == 1) {
          return this.commissionScore_form.teacherProfileScoreCriterion.filter(i => i.id == 1 || i.id == 5)
        } else if (this.selected_profile_types.practic == 1) {
          return this.commissionScore_form.teacherProfileScoreCriterion.filter(i => i.id == 1 || i.id == 6)
        } else {
          return this.commissionScore_form.teacherProfileScoreCriterion
        }
      },
      anonymousVoteInfo() {
        return this.commissionScore_form.candidateList.find(i => i.user === this.anonymous_vote_user_id).anonymous_vote
      },
      disciplineAnonymousVoteInfo() {


        return this.commissionScore_form.candidateList.find(i => i.user === this.discipline_anonymous_vote_user_id).applications
          .find(i => i.kkk_interview_time_id === this.discipline_anonymous_vote_kkk_interview_time_id).kkk_anonymous_vote
      }
      // disciplineAnonymousVoteInfo() {
      //   return this.commissionScore_form.candidateList.find(i=>i.user === this.anonymous_vote_user_id).anonymous_vote
      // }
    },
    methods: {
      decryptCheckRole,
      ...mapActions('commissionScore', ['GET_CANDIDATE_LIST', 'GET_DISCIPLINE_TEACHING_SCORE_CRITERION',
        'GET_TEACHER_PROFILE_SCORE_CRITERION', 'POST_TEACHER_PROFILE_SCORE', 'POST_DISCIPLINE_TEACHING_SCORE', 'POST_ANONYMOUS_VOTE', 'POST_DISCIPLINE_ANONYMOUS_VOTE',
        'GET_MEMBER_STATUS', 'GET_COMMISSION_MEMBERS', 'POST_MEMBER_STATUS', 'PUT_MEMBER_STATUS']),
      ...mapMutations('userinfo', ['SET_USER_ID']),
      ...mapActions('kkk', ['PUT_CONTRACT', 'GET_TEACHING_POSITIONS']),
      async submitAnonymousVote(vote) {
        this.anonymousVoteDisplay = false
        let form = {}
        form['vote'] = vote
        form['user_id'] = this.anonymous_vote_user_id


        console.log(form, 'submit anonymous vote')

        let postData = await this.POST_ANONYMOUS_VOTE(form)
        if (postData) {
          await this.GET_CANDIDATE_LIST()
          this.$message({text: 'Выбор сохранен'})
        }

        this.anonymous_vote_user_id = null


      },
      openAnonymousVoteDialog(user_id) {
        this.anonymousVoteDisplay = true
        this.anonymous_vote_user_id = user_id
      },
      closeAnonymousVoteDialog() {
        this.anonymousVoteDisplay = false
      },
      async submitDisciplineAnonymousVote(vote) {
        this.disciplineAnonymousVoteDisplay = false
        let form = {}
        form['vote'] = vote
        form['kkk_interview_time_id'] = this.discipline_anonymous_vote_kkk_interview_time_id


        console.log(form, 'submit discipline anonymous vote')

        let postData = await this.POST_DISCIPLINE_ANONYMOUS_VOTE(form)
        if (postData) {
          await this.GET_CANDIDATE_LIST()
          this.$message({text: 'Выбор сохранен'})
        }

        this.discipline_anonymous_vote_kkk_interview_time_id = null


      },
      openDisciplineAnonymousVoteDialog(kkk_interview_time_id, user_id) {
        this.disciplineAnonymousVoteDisplay = true
        this.discipline_anonymous_vote_kkk_interview_time_id = kkk_interview_time_id
        this.discipline_anonymous_vote_user_id = user_id
      },
      closeDisciplineAnonymousVoteDialog() {
        this.disciplineAnonymousVoteDisplay = false
      },
      openDisciplineInfoDialog(user_id, discipline_id) {
        this.disciplineInfoDisplay = true
        console.log(discipline_id, 'discipline info discipline_id ')
        console.log(user_id, 'discipline info user_id ')
        this.info_discipline_id = discipline_id
        this.info_user_id = user_id
      },
      closeDisciplineInfoDialog() {
        this.disciplineInfoDisplay = false
      },
      goToUser(userId) {
        this.SET_USER_ID(userId)
        this.$router.push('/user')
      },
      goToSyllabus(user_id, discipline_id) {

        window.open(`https://back.uib.kz/syllabus_data/syllabus-print/get-syllabus?user_id=${user_id}&education_discipline_id=${discipline_id}`)
        //this.$router.push(`https://back.uib.kz/syllabus_data/syllabus-print/get-syllabus?user_id=${user_id}&education_discipline_id=${discipline_id}`)
      },
      openDisciplineTeachingScoreDialog(kkk_interview_time_id, user_id) {

        console.log(kkk_interview_time_id, 'openDisciplineTeachingScoreDialog kkk_interview_time_id')
        console.log(user_id, 'openDisciplineTeachingScoreDialog user_id')
        this.disciplineTeachingScoreDisplay = true
        this.discipline_teaching_score_kkk_interview_time_id = kkk_interview_time_id
        this.discipline_teaching_score_user_id = user_id
      },
      closeDisciplineTeachingScoreDialog() {
        this.disciplineTeachingScoreDisplay = false
      },
      changeDisciplineTeachingScore(property, e, val = 'value') {
        let value = e.target[val]
        this.disciplineTeachingScore[property] = value
        console.log(this.disciplineTeachingScore, 'disciplineTeachingScore')
      },
      openTeacherProfileScoreDialog(user_id, profile_types) {
        this.teacherProfileScoreDisplay = true
        this.teacher_profile_score_user_id = user_id
        this.selected_profile_types = profile_types
        console.log(this.selected_profile_types, 'selected_profile_types')
      },
      closeTeacherProfileScoreDialog() {
        this.teacherProfileScoreDisplay = false
      },
      changeTeacherProfileScore(property, e, val = 'value') {
        let value = e.target[val]
        this.teacherProfileScore[property] = value
        console.log(this.teacherProfileScore, 'teacherProfileScore')
      },

      async submitDisciplineTeachingScore() {
        this.disciplineTeachingScoreDisplay = false
        let form = this.disciplineTeachingScore
        form['interview_time_id'] = this.discipline_teaching_score_kkk_interview_time_id

        console.log(form, 'submit discipline teaching score')

        let postData = await this.POST_DISCIPLINE_TEACHING_SCORE(form)
        if (postData) {
          await this.GET_CANDIDATE_LIST()
          this.$message({text: 'Оценки поставлены'})
        }
        this.disciplineTeachingScore = {}
        this.discipline_teaching_score_kkk_interview_time_id = null
      },

      async submitTeacherProfileScore() {
        this.teacherProfileScoreDisplay = false
        let form = this.teacherProfileScore
        form['user_id'] = this.teacher_profile_score_user_id

        if (this.selected_profile_types.simple == 1) {
          form['type'] = 1
        } else if (this.selected_profile_types.practic == 1) {
          form['type'] = 2
        } else if (this.selected_profile_types.lang == 1) {
          form['type'] = 2
        }

        console.log(form, 'submit teacher profile score')

        let postData = await this.POST_TEACHER_PROFILE_SCORE(form)
        if (postData) {
          await this.GET_CANDIDATE_LIST()
          this.$message({text: 'Оценки профиля поставлены'})
        }
        this.teacherProfileScore = {}
        this.teacher_profile_score_user_id = null
      },
      async saveMemberStatus(is_active) {
        this.loadingMemberStatus = true

        let res = false

        this.memberStatus.is_active = is_active
        if (this.memberStatus.id) {
          res = await this.PUT_MEMBER_STATUS(this.memberStatus)
        } else {
          res = await this.POST_MEMBER_STATUS(this.memberStatus)
        }

        if (res) {
          await this.getCommissionMembers()
          await this.getMemberStatus()
          this.$message({title: 'Сохранено'})
        }

        this.loadingMemberStatus = false
      },
      async getMemberStatus() {
        this.memberStatus = await this.GET_MEMBER_STATUS() || {is_active: 0}
      },
      async getCommissionMembers() {
        this.commissionMembers = await this.GET_COMMISSION_MEMBERS()
      },


      openAddContractDialog(userId) {
        const user = this.commissionScore_form.candidateList.find(i => i.user == userId)
        if (user) {
          this.contracts = user.contracts.map(i => ({
            ...i,
            work_start_date: convertTimestampToDate(i.work_start_date),
            contract_start_date: convertTimestampToDate(i.contract_start_date),
            contract_end_date: convertTimestampToDate(i.contract_end_date)
          }))

          this.contractUserName = `${user?.lastname} ${user?.firstname}`
          this.displayAddContract = true
        }
      },
      closeAddContractDialog() {
        this.displayAddContract = false
      },
      async addContract(id) {
        const contract = this.contracts.find(i=>i.id==id)

        const form = {
          ...contract,
          work_start_date: convertDateInputToTimestamp(contract.work_start_date),
          contract_start_date: convertDateInputToTimestamp(contract.contract_start_date),
          contract_end_date: convertDateInputToTimestamp(contract.contract_end_date),
        }

        const res = await this.PUT_CONTRACT(form)
        if (res) {
          await this.GET_CANDIDATE_LIST()
          this.$message({title: 'Контракт успешно сохранен'})
        }

        this.closeAddContractDialog()
      },
      changeContractTerm(e, id) {
        const index = this.contracts.findIndex(i=>i.id==id)
        this.contracts[index].contract_term = e.target.value
        this.contracts[index].contract_end_date = this.getContractEndDate(+this.contracts[index].contract_term)
      },
      getContractEndDate(contract_term) {
        if ([1, 2, 3].includes(contract_term)) {
          return `${new Date().getFullYear() + contract_term}-08-31`
        }

        let month = new Date().getMonth()
        let countMonth = month + 6 + 1

        let day = new Date().getDate()
        day = (day < 10 ? '0' : '') + day

        let year = new Date().getFullYear()

        if (countMonth <= 12) {
          month = month + 6
        } else {
          month = countMonth - 12
          year += 1
        }
        month = (month < 10 ? '0' : '') + month
        return `${year}-${month}-${day}`
      },

      // confirm(user) {
      //   this.$confirm.require({
      //     message: 'Вы уверены что хотите принять?',
      //     header: 'Принятие',
      //     icon: 'pi pi-exclamation-triangle',
      //     accept: async () => {
      //       let app = this.checkedApplications.filter(i=>i.user_id == user)
      //       let form = app.map(i => ({
      //         user_syllabus_id: i.user_syllabus_id,
      //         commission_status: 1,
      //       }))
      //       console.log(form, 'submit confirm form')
      //
      //       let postData = await this.POST_COMMISSION_DECISION(form)
      //       if (postData) {
      //         await this.GET_VACANCY_APPLICATIONS()
      //         this.$message({text: 'Заявки на вакансию приняты'})
      //         //this.$toast.add({severity:'success', summary:'Принято', detail:'Вы приняли', life: 3000});
      //       }
      //       this.checkedApplications = []
      //       this.reject_user = null
      //
      //     },
      //
      //   });
      // },

    },


    async mounted() {
      await this.GET_TEACHING_POSITIONS()


      await this.getMemberStatus()
      await this.getCommissionMembers()

      await this.GET_CANDIDATE_LIST()
      await this.GET_DISCIPLINE_TEACHING_SCORE_CRITERION()
      await this.GET_TEACHER_PROFILE_SCORE_CRITERION()
      this.loading = false;
      this.interval = setInterval(() => {
        this.GET_CANDIDATE_LIST()
      }, 5000)
    },
    beforeUnmount() {
      clearInterval(this.interval)
    },
  }

</script>